/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.com/docs/reference/config-files/gatsby-browser/
 */

// You can delete this file if you're not using it
export const onClientEntry = () => {
  // Disable the loading indicator
  window.__deactivate_loading_indicator = true
}
